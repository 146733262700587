<template>
  <div class="pagePadding">
    <div class="topDiv">
      <Form inline class="formMarginBtm20">
        <FormItem>
          <span class="label">退货单号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.order_number"></i-input>
        </FormItem>
        <FormItem :label-width="70">
          <span class="label">下单时间：</span>
          <DatePicker type="daterange" class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime"></DatePicker>
        </FormItem>
        <FormItem :label-width="70">
          <span class="label">状态：</span
          ><Select class="iviewIptWidth250" v-model="queryFrom.purchase_return_state" clearable>
            <Option v-for="item in orderStateList" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <!--          <span class="pageBtn finger btnReset marginLeft20">新增</span>-->
        </FormItem>
      </Form>
    </div>
    <div class="tabelDiv">
      <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" totalText="条记录">
        <div slot="summary" class="summary" v-if="listData.length > 0">
          <div>
            <span>合计金额：</span>
            <span class="color389">¥{{ totalMoney }}</span>
          </div>
        </div>
      </Table>
    </div>
    <!--    审核-->
    <Modal v-model="returnStatus" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认收货？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="approved">确认</span>
          <span class="pageBtn finger btnCancle" @click="returnStatus = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  components: {
    Table
  },
  computed: {},
  data() {
    return {
      returnStatus: false,
      totalMoney: 0,
      isLoad: false,
      orderStateList: [
        {
          name: '待收货',
          value: '3'
        },
        {
          name: '已完成',
          value: '9'
        }
      ],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65
        },
        {
          title: '退货单号',
          align: 'center',
          key: 'order_number',
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    }
                  }
                },
                param.row.order_number
              )
            ])
          },
          width: 160
        },
        {
          title: '产品',
          key: 'product_list',
          minWidth: 250,
          align: 'center'
        },
        {
          title: '数量',
          key: 'total_number',
          width: 80,
          align: 'center'
        },
        {
          title: '退货金额',
          align: 'center',
          width: 170,
          render: (h, param) => {
            return h('div', [h('span', param.row.total_amount ? '¥' + param.row.total_amount : '')])
          }
        },
        {
          title: '下单时间',
          key: 'creatTime',
          sortable: 'custom',
          width: 190,
          align: 'center'
        },
        {
          title: '备注',
          key: 'note',
          minWidth: 160,
          align: 'center'
        },
        {
          title: '状态',
          key: 'purchase_return_state_str',
          align: 'center',
          width: 150
        },
        {
          title: '操作',
          align: 'center',
          width: 130,
          render: (h, param) => {
            if (param.row.purchase_return_state == '3') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer'
                    },
                    class: { disableUse: this.modifyAble },
                    on: {
                      click: () => {
                        this.receipt(param.row)
                      }
                    }
                  },
                  '收货'
                )
              ])
            } else if (param.row.purchase_return_state == '9') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.$Message.warning('功能开发中...')
                      }
                    }
                  },
                  '导出清单'
                )
              ])
            }
          }
        }
      ],
      listData: [],
      total: 1,
      queryFrom: {
        page: 1,
        rows: 10,
        is_supplier: '1',
        order_number: '',
        supplier_id: '',
        start_time: '',
        end_time: '',
        purchase_return_state: ''
      },
      deleteId: '',
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false // 查找权限
    }
  },
  mounted() {
    this.query()
    this.getRightVisibal()
  },
  methods: {
    sortChange(data) {
      console.log(data)
      if (this.queryFrom.sort_str === 'creatTime') {
        this.queryFrom.sort_str = 'create_time'
      } else {
        this.queryFrom.sort_str = data.key
      }
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    // 确认收货
    approved() {
      this.$http.put(this.$api.pruchaseReturnReceive, { order_number: this.deleteId }, true).then(res => {
        this.returnStatus = false
        this.query()
      })
    },
    // 点击收货按钮
    receipt(row) {
      this.deleteId = row.order_number
      this.returnStatus = true
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.start_time = null
        this.queryFrom.end_time = null
      } else {
        this.queryFrom.start_time = e[0]
        this.queryFrom.end_time = e[1]
      }
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      this.$http.get(this.$api.pruchaseReturnManage, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_amount
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
    },
    ship() {
      this.$router.push({ path: '/purchaseReturnsDetails' })
    },
    goDetails(row) {
      this.$router.push({
        path: '/purchaseReturnsDetails',
        query: {
          id: row.id
        }
      })
    },
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    }
  }
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
